import _isEmpty from "lodash/isEmpty"
import moment from 'moment'
import 'moment/locale/ro'
import {locales} from '../locales'

const axios = require("axios");

const errorInterceptor = error => {
    if (!error.response) {
        // console.log('**Network/Server error');
        return Promise.reject(error);
    }
    switch (error.response.status) {
        case 400:
            break;
        case 401: 
            location.reload()
            return;
        default:
    }
    // console.log(error.response.status, error.message);
    return Promise.reject(error);
}

axios.interceptors.response.use(
    function (res) {
        if (res.request && res.request.responseURL && res.request.responseURL.includes("/accounts/sign_in")) {
            location.reload()
        }
        return res;
    },
    errorInterceptor
);


export default class Utils {

    static d = (route, options = {}) => {
        const BASE_URL = '/dashboard/v2';
        return BASE_URL + route;
    }

    static restRoute = (url, action, id) => {
        switch (action) {
            case 'index':
                url += '.json'
                break
            case 'edit':
                url += '/' + id + '/edit'
                break
            case 'new':
                url += '/new'
                break
            case 'create':
                break
            case 'show':
            case 'update':
            case 'destroy':
                url += '/' + id
        }
        return url
    }


    static roles_to_text = (roles) => {
        let result = []
        if (roles.leader === 1) result.push('Conducator')
        if (roles.admin === 1) result.push('Administrator')
        else {
            if (roles.manager === 1) result.push('Acces Complet')
            else {
                if (roles.hr === 1) result.push('Resurse Umane (Citire)')
                if (roles.hr === 2) result.push('Resurse Umane (Editor)')
                if (roles.ssm === 2) result.push('SSM (Editor)')
                if (roles.ssm === 1) result.push('SSM (Citire)')
            }
        }
        return result.join(' + ') || 'Nu are niciun rol'
    }

    // return array range from 1...N
    static range = (N) => {
        return Array.from(Array(N).keys()).map(k => k + 1)
    }

    static toQueryString = (paramsObject) => {
        return Object
            .keys(paramsObject)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
            .join('&')

    }

    static create = (url, params, callback) => {
        axios.post(Utils.restRoute(url, 'create'), Object.assign({authenticity_token: CSRF_TOKEN}, params))
            .then(res => callback(res))
            .catch(error => callback({data: {success: false}}))
    }
    static update = (url, id, params, callback) => {
        axios.patch(Utils.restRoute(url, 'update', id), Object.assign({authenticity_token: CSRF_TOKEN}, params))
            .then(res => callback(res))
            .catch(error => callback({ data: { success: false } }))
    }
    static put = (url, params, callback) => {
        axios.put(url, Object.assign({authenticity_token: CSRF_TOKEN}, params))
            .then(res => callback(res))
            .catch(error => callback({data: {success: false}}))
    }
    static post = (url, params, callback) => {
        axios.post(url, Object.assign({authenticity_token: CSRF_TOKEN}, params))
            .then(res => callback(res))
            .catch(error => callback({data: {success: false}}))
    }
    static show = (url, id, callback, params = {}) => {
        const queryString = !_isEmpty(params) ? ('?' + Utils.toQueryString(params)) : ''
        axios.get(Utils.restRoute(url, 'show', id) + queryString)
            .then(res => callback(res))
            .catch(error => callback({data: {success: false}}))
    }
    static edit = (url, id, callback, params = {}) => {
        const queryString = !_isEmpty(params) ? ('?' + Utils.toQueryString(params)) : ''
        axios.get(Utils.restRoute(url, 'edit', id) + queryString)
            .then(res => callback(res))
    }

    static index = (url, callback) => {
        axios.get(Utils.restRoute(url, 'index'))
            .then(res => callback(res))
    }

    static destroy = (url, id, callback, params = {}) => {
        axios.delete(Utils.restRoute(url, 'destroy', id), {params: Object.assign({authenticity_token: CSRF_TOKEN}, params)})
            .then(res => callback(res))
            .catch(error => callback({data: {success: false}}))
    }

    static customRoute = (url, params = {}) => {
        return url + (!_isEmpty(params) ? ('?' + Utils.toQueryString(params)) : '')
    }

    static getUrl = (url, params, callback) => {
        axios.get(Utils.customRoute(url, params))
            .then(res => callback(res))
            .catch(error => callback({data: {success: false}}))
    }

    static validateEmail = (email) => {
        return email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    static blank = str => {
        return !str || str === undefined || str === ''
    }

    static fdate = (date, format = 'LLL') => {
        if (Utils.blank(date)) return ''
        return moment(date).format(format)
    }

    static uploadFile = (url, file, params, callback) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('authenticity_token', CSRF_TOKEN)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            params: params
        }
        axios.post(url, formData, config).then(res => callback(res))
    }

    static updateFile = (url, file, params, callback) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('authenticity_token', CSRF_TOKEN)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            params: params
        }
        axios.patch(url, formData, config).then(res => callback(res))
    }

    static setAuthToken = (auth_token) => {
        axios.defaults.headers.common = {Authorization: auth_token}
    }

    static clearAuthToken = () => {
        axios.defaults.headers.common = {}
    }

    static  accessGroups = () => {
        return {
            g1: 'Public',
            g2: 'SSM',
            g3: 'SU',
            g4: 'HR',
            g5: 'Privat'
        }
    }

    static filterObject = (object, keys) => {
        try {
            let data = {}
            Object.keys(object).filter(k => keys.includes(k)).map(k => data[k] = object[k])
            return data
        } catch (error) {
            // console.log(error)
            return {}
        }
    }

    static locales = () => {
        return locales
    }

     static limitString = (inputString, maxLength) => {
        if (inputString && inputString.length > maxLength) {
            return inputString.slice(0, maxLength) + '...';
        }
        return inputString;
    }

    static moveUpById(list, id) {
        const index = list.findIndex(item => item.id === id)
        if (index !== -1 && index > 0) {
            let newList = [...list]
            let temp = newList[index]
            newList[index] = newList[index - 1]
            newList[index - 1] = temp
            return newList
        }
        return list
    }

    static moveDownById(list, id) {
        const index = list.findIndex(item => item.id === id)
        if (index !== -1 && index < list.length - 1) {
            let newList = [...list]
            let temp = newList[index]
            newList[index] = newList[index + 1]
            newList[index + 1] = temp
            return newList
        }
        return list
    }

    static moveById(direction, list, id) {
        if (direction === 'up') return Utils.moveUpById(list, id)
        if (direction === 'down') return Utils.moveDownById(list, id)
        return list
    }
    
}