export const translationsRU = {
  'save': 'Сохранить',
  'send': 'Отправить',
  'back': 'Назад',
  // Auth/Layout.jsx
  'auth': 'Аутентификация',
  'register': 'Регистрация',
  'reset_pwd': 'Сброс пароля',
  'edit_pwd': 'Изменить пароль',
  'confirm': 'Подтверждение',
  // Auth/ChangePassword.jsx
  'edit_pwd_1': 'Запрос на сброс пароля больше не действителен.',
  'edit_pwd_2': 'Введите новый пароль',
  'password': 'Пароль',
  'repeat_password': 'Повторите пароль',
  // Auth/Login.jsx
  'email': 'Электронная почта',
  'remember_me': 'Запомнить меня',
  'connect': 'Войти',
  'continue_with': 'Продолжить с',
  'processing': 'Обработка...',
  'forgot_pwd': 'Забыли пароль?',
  'or': 'или',
  // Auth/NewConfirmation.jsx
  'nc_1': 'Отправлено письмо с инструкциями по подтверждению адреса электронной почты.',
  'nc_2': 'Пожалуйста, проверьте свою электронную почту (включая папку "Спам"), чтобы продолжить.',
  'nc_3': 'Введите адрес электронной почты, чтобы получить новое подтверждение по электронной почте.',
  'connect_1': 'Подключение',
  // Auth/Register.jsx
  'reg_1': 'Заполните форму для новой учетной записи:',
  'email_1': 'Ваш адрес электронной почты',
  'pwd_1': 'Выберите пароль',
  'pwd_2': 'Подтвердите пароль',
  'agree_1': 'Я согласен с',
  't&c_link': 'terms-and-conditions',
  'agree_2': 'Условиями и положениями',
  'create_account': 'Создать аккаунт',
  // Auth/ResetPassword.jsx
  'reset_1': 'Отправлено письмо с подробностями по изменению пароля.',
  'reset_2': 'Пожалуйста, проверьте свою электронную почту, чтобы продолжить.',
  'reset_3': 'Введите адрес электронной почты',
}
