import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline'
import theme from '../Theme'
import StyledLayout from './StyledLayout';


export default class Layout extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <React.Fragment>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <StyledLayout {...this.props} />
          </ThemeProvider>
        </StyledEngineProvider>
      </React.Fragment>
    );
  }
}