import React, {Fragment} from 'react'
import {Grid, Typography, TextField, Button, FormControl, FormHelperText, Checkbox, Paper} from '@mui/material'
import translate from './Locales/translate'


export default class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: this.props.account.email || '',
            terms: false,
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleTerms = e => {
        this.setState({[e.target.name]: e.target.checked});
    }


    render() {
        const {classes, login_url, errors} = this.props
        const {email, terms} = this.state
        return (
            <Fragment>
                <form className={classes.container} action="/accounts" method="post">
                    <input type="hidden" value={CSRF_TOKEN} name="authenticity_token"/>
                    <input type="hidden" value={this.props.code} name="code"/>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1">{translate('reg_1')}</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl variant="standard" fullWidth error={!!errors.email}>
                                <TextField
                                    variant="standard"
                                    name="account[email]"
                                    label={translate('email_1')}
                                    className={classes.textField}
                                    defaultValue={email}
                                    onChange={this.handleChange('email')}
                                    margin="normal"
                                    fullWidth
                                    type={'email'}
                                    autoComplete={'username'}
                                    required
                                    inputProps={{readOnly: !!this.props.account.email}} />
                                <FormHelperText
                                    id="account[email]-error-text">{errors.email}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl variant="standard" fullWidth error={!!errors.password}>
                            <TextField
                                variant="standard"
                                name={"account[password]"}
                                label={translate('pwd_1')}
                                className={classes.textField}
                                onChange={this.handleChange('password')}
                                margin="normal"
                                fullWidth
                                type={'password'}
                                autoComplete={'new-password'}
                                required={true} />
                                <FormHelperText
                                    id="account[password]-error-text">{errors.password}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl variant="standard" fullWidth error={!!errors.password_confirmation}>
                            <TextField
                                variant="standard"
                                name={"account[password_confirmation]"}
                                label={translate('pwd_2')}
                                className={classes.textField}
                                onChange={this.handleChange('password_confirmation')}
                                margin="normal"
                                fullWidth
                                type={'password'}
                                autoComplete={'new-password'}
                                required={true} />
                                <FormHelperText id="account[password_confirmation]-error-text">
                                    {errors.password_confirmation}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={11}>
                            <div className={classes.rowTerms}>
                                <Checkbox
                                    name={'terms'}
                                    checked={terms}
                                    onChange={this.handleTerms}
                                    value={"acceptat"}
                                    classes={{
                                        root: classes.checkbox,
                                        checked: classes.checked,
                                    }}
                                />
                                <Typography>
                                    {translate('agree_1')} <a href={`/document/${translate('t&c_link')}`} target='_blank'>{translate('agree_2')}</a>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid container justifyContent={'flex-end'}>
                            <Button variant="contained" size="medium" color="primary" disabled={!terms}
                                    className={classes.button} type={'submit'} name={'commit'}>
                                {translate('create_account')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Grid container style={{marginTop: 24}}>
                    <Button size="small" color="primary" onClick={() => window.location = login_url}>
                        {translate('back')}
                    </Button>
                </Grid>
            </Fragment>
        );
    }
}