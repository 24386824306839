
function translateFunction(translations, key, vars = {}) {
  const locale = translations[LOCALE] && LOCALE || 'ro'
  const translation = translations[locale][key];
  if (!translation) {
    console.warn(`Translation not found for key: ${key}`)
    return key
  }

  let translatedText = translation
  Object.keys(vars).forEach((varKey) => {
    const placeholder = `{${varKey}}`
    const value = vars[varKey]
    translatedText = translatedText.replace(new RegExp(placeholder, 'g'), value)
  })
  return translatedText
}

export default translateFunction
