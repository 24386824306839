import { createTheme } from '@mui/material/styles';
import darkTheme from './Themes/dark'
import lightTheme from './Themes/light'

function theme() {
    try {
        const theme = THEME
        if (theme === 'dark') {
            return darkTheme;
        } else {
            return lightTheme;
        }
    }
    catch (e) {
        return lightTheme;
    }  

}


export default createTheme(theme());
