import React, { useState, useEffect, useRef } from "react";
import Utils from "../Dashboard/Utils";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Sensors } from "@mui/icons-material";

const Rfid = (props) => {
  const [scanning, setScanning] = useState(true);
  const [rfidData, setRfidData] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [organization, setOrganization] = useState("");
  const [counter, setCounter] = useState(2);
  const buttonRef = useRef(null);
  const limita_caractere = parseInt(props.configs?.limita_caractere)
  const limit_scan = props.configs?.delimitator_scanare === "limita_caractere";
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (successMessage) {
      if (counter > 0) {
        const timer = setTimeout(() => setCounter(counter - 1), 1000);
        return () => clearTimeout(timer);
      } else {
        buttonRef.current.click();
      }
    }
  }, [counter, successMessage]);

  useEffect(() => {
    calculateProgress();
    if (!limit_scan || !limita_caractere || limita_caractere < 1) {
      return;
    }
    if (rfidData.length >= limita_caractere) {
      postScanData();
    }
  }, [rfidData]);

  const calculateProgress = () => {
    if (!scanning) return;
    try {
      const limit = limita_caractere || 10;
      const p = rfidData.length / limit * 100;
      setProgress(Math.min(p, 100));
    } catch (e) {
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      return postScanData();
    }
    setRfidData((prevData) => prevData + event.key);
  };

  const postScanData = () => {
    Utils.post("/personal/rfid", { code: rfidData }, (res) => {
      if (res.data && res.data.success) {
        setSuccessMessage(res.data.message)
        setScanning(false);
      } else {
        setErrorMessage(res.data.message || "Eroare la scanare")
        setScanning(false);
      }
    });
  };

  useEffect(() => {
    if (scanning) {
      document.addEventListener("keydown", handleKeyPress);
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [scanning, handleKeyPress]);

  const handleStartScan = () => {
    setScanning((prevScanning) => !prevScanning);
    setRfidData("");
    setErrorMessage("");
  };
  const obfuscatedData = scanning
    ? ""
    : rfidData
      .split("")
      .map((char, index) => (index < rfidData.length - 2 ? "*" : char))
      .join("");

  const scanner = () => (
    <div>
      {scanning &&
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop={4}>
          <Sensors color="primary" style={{ fontSize: 80, marginBottom: 32 }} />
          <Typography variant="h6" gutterBottom>Scaneaza card-ul RFID</Typography>
          <LinearProgress variant="determinate" value={progress} style={{width: "80%", height: 8, marginTop: 32}} />
        </Box>
      }
      {!scanning && (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {errorMessage && <>
            <Typography gutterBottom variant="h6" style={{ marginTop: 32 }}>Card RFID scanat</Typography>
            <Typography gutterBottom variant="h6" style={{ marginBottom: 32 }}>{rfidData}</Typography>
            <Typography color="secondary" gutterBottom>{errorMessage}</Typography>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={handleStartScan}
            >
              Scaneaza din nou
            </Button>
          </>
          }
          {successMessage && <>
            <Typography gutterBottom variant="h6" style={{ marginTop: 32 }}>Conectat cu succes</Typography>
            <Typography gutterBottom variant="h6" style={{ marginBottom: 32 }}>{successMessage}</Typography>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              ref={buttonRef}
              onClick={() => window.location = "/personal"}
            >
              Continua in cont ({counter})
            </Button>
          </>
          }

        </Box>
      )}
    </div>
  );

  const selectOrganization = () => (
    <div>
      <Typography gutterBottom>Introduceti subdomeniul organizatiei</Typography>
      <FormControl fullWidth style={{ marginBottom: 32 }}>
        <TextField
          variant="standard"
          name="organization"
          label={"Subdomeniu"}
          onChange={(e) => setOrganization(e.target.value)}
          margin="normal"
          required={true}
        />
      </FormControl>
      <Grid container justifyContent={"space-between"}>
        <Button
          variant="outlined"
          size="medium"
          onClick={() => (window.location = "/accounts/sign_in")}
        >
          Anuleaza
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() =>
          (window.location =
            "//" + organization + "." + props.root_host + "/rfid_sign_in")
          }
        >
          Continua
        </Button>
      </Grid>
    </div>
  );

  if (!props.subdomain) return <div>Subdomeniu invalid</div>;
  if (props.subdomain !== "www" && !props.enabled)
    return (
      <Typography color='secondary'>
        Accesul prin carduri RFID nu este activat pentru aceasta companie.
      </Typography>
    );

  return (
    <div style={{ minHeight: 250 }}>
      {props.subdomain === "www" && selectOrganization()}
      {props.subdomain !== "www" && props.enabled && scanner()}
    </div>
  );
};

export default Rfid;
