import { translationsRO } from './ro'
import { translationsEN } from './en'
import { translationsRU } from './ru'
import translateFunction from '../../TranslateFunction'

const translations = {
  ro: translationsRO,
  en: translationsEN,
  ru: translationsRU
}

function translate(key, vars = {}) {
  return translateFunction(translations, key, vars)
}

export default translate