import React, {Fragment} from 'react'
import {TextField, Button, Grid, Typography, FormHelperText, FormControl} from '@mui/material'
import Utils from '../Dashboard/Utils'
import translate from './Locales/translate'


export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: this.props.email || '',
            errors: this.props.errors || {}
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
            errors: {...this.state.errors, [name]: ''}
        })
    }

    submitRequest = () => {
        this.setState({loading: true, errors: {}})
        Utils.create(this.props.post_reset_url, {account: {email: this.state.email}}, res => {
            if (res.data && res.data.success) {
                this.setState({success: true, loading: false});
            } else {
                this.setState({errors: res.data.errors, loading: false})
            }
        })
    }

    render() {
        const {classes, login_url} = this.props
        const {loading, errors, success, email} = this.state
        return (
            <Fragment>
                {success &&
                <Fragment>
                    <Typography variant="h6" gutterBottom>{translate('reset_1')}</Typography>
                    <br/>
                    <Typography variant="body1">{translate('reset_2')}</Typography>
                </Fragment>}
                {!success &&
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">{translate('reset_3')}</Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <FormControl variant="standard" fullWidth error={!!errors.email}>
                        <TextField
                            variant="standard"
                            name="account[email]"
                            label={translate('email')}
                            className={classes.textField}
                            defaultValue={email}
                            onChange={this.handleChange('email')}
                            margin="normal"
                            fullWidth
                            type={'email'}
                            required={true} />
                            <FormHelperText
                                id="account[email]-error-text">{errors.email}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid container justifyContent={'flex-end'}>
                        <Button variant="contained" size="medium" color="primary"
                                className={classes.button} onClick={this.submitRequest} disabled={loading}>
                            {translate('reset_pwd')}
                        </Button>
                    </Grid>
                </Grid>}
                <Grid container style={{marginTop: 24}}>
                    <Button size="small" color="primary" onClick={() => window.location = login_url}>
                        {translate('connect_1')}
                    </Button>
                </Grid>
            </Fragment>
        );
    }
}
