const gray = { 50: '#FBFCFE', 100: '#EAF0F5', 200: '#D6E2EB', 300: '#BFCCD9', 400: '#94A6B8', 500: '#5B6B7C', 
                      600: '#4C5967', 700: '#364049', 800: '#131B20', 850: '#101418', 900: '#090E10' };

const theme = {
  palette: {
    mode: 'dark',
    background: {
      default: '#1F1F1F',
    },
    primary: {
      main: '#40A6FF'
    },
    secondary: {
      main: '#F85149'
    },
    success: {
      main: '#2EA043'
    },
  },
  components: {
    // Drawer and Topbar
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
          backgroundColor: '#181818',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: '#1F1F1F',
          borderBottom: "solid 1px #364049"
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundImage: 'none',
          backgroundColor: 'rgba(22, 26, 29)',
        }),
      },
    },
  },
};

export default theme;
