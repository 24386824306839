export const translationsRO = {
  'save': 'Salveaza',
  'send': 'Trimite',
  'back': 'Inapoi',
  // Auth/Layout.jsx
  'auth': 'Autentificare',
  'register': 'Inregistrare',
  'reset_pwd': 'Reseteaza parola',
  'edit_pwd': 'Modifica parola',
  'confirm': 'Confirmare',
  // Auth/ChangePassword.jsx
  'edit_pwd_1': 'Cererea de resetare parola nu mai este valabila.',
  'edit_pwd_2': 'Introdu o noua parola',
  'password': 'Parola',
  'repeat_password': 'Repeta Parola',
  // Auth/Login.jsx
  'email': 'Email',
  'remember_me': 'Tine-ma minte',
  'connect': 'Conecteaza-te',
  'continue_with': 'Continua cu',
  'processing': 'Procesare...',
  'forgot_pwd': 'Ai uitat parola?',
  'or': 'sau',
  // Auth/NewConfirmation.jsx
  'nc_1': 'A fost trimis un email cu instructiunile de confirmare a adresei de email.',
  'nc_2': 'Va rugam verificati contul de email (inclusiv in SPAM) pentru a continua.',
  'nc_3': 'Introdu adresa de email pentru a primi un nou email de confirmare.',
  'connect_1': 'Conectare',
  // Auth/Register.jsx
  'reg_1': 'Completează formularul pentru cont nou:',
  'email_1': 'Adresa ta de email',
  'pwd_1': 'Alege o parola',
  'pwd_2': 'Confirma parola',
  'agree_1': 'Sunt de acord cu',
  't&c_link': 'termeni-si-conditii',
  'agree_2': 'Termeni si Conditii',
  'create_account': 'Creeaza cont',
  // Auth/ResetPassword.jsx
  'reset_1': 'A fost trimis un email cu detaliile de modificare parola.',
  'reset_2': 'Va rugam verificati contul de email pentru a continua.',
  'reset_3': 'Introdu adresa de email',
}
