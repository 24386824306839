const theme = {
  palette: {
    mode: 'light', 
      primary: {
      main: '#1d60b2',
        light: '#5e8de5',
          dark: '#003782',
            contrastText: '#fff',
            },
    secondary: {
      main: '#ed4f32',
        light: '#ff825e',
          dark: '#b31206',
            contrastText: '#fff',
            },
    success: {
      main: '#4caf50'
    }
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          borderBottom: "solid 1px #e5e5e5"
        },
      },
    },
  },
};

export default theme;