import React, {Fragment} from 'react'
import {Typography, TextField, Button, Grid, Checkbox, Divider} from "@mui/material"
import Erp from '../Erp'
import translate from './Locales/translate'


export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: this.props.email || ''
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }


    render() {
        const {classes, login_url, reset_url, errors, notices, saml_url, saml_name, email_disabled} = this.props
        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        {notices &&
                        <Typography variant="body1" color={"primary"}>{notices.join(' ')}</Typography>}
                        {errors &&
                        <Typography variant="body1" color={"secondary"}>{errors.join(' ')}</Typography>}
                    </Grid>
                </Grid>

                
                {saml_url && 
                <form className={classes.container} action={saml_url} method="post" 
                    onSubmit={(e) => {this.setState({submiting: true}); }}>
                    <input type="hidden" value={CSRF_TOKEN} name="authenticity_token"/>
                    <Grid container style={{marginBottom: 36}}>
                        <Grid item xs={12}>
                            <Button variant={email_disabled ? "contained" : "outlined"} size="large" color="primary" fullWidth
                                className={classes.button} style={{textTransform: 'none'}}
                                disabled={this.state.submiting} type='submit'>
                                {!this.state.submiting && Erp.ssoName(translate('continue_with'), saml_name)}
                                {this.state.submiting && translate('processing')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>}


                {saml_url && !email_disabled && 
                <Typography style={{textAlign: 'center', color: '#666', fontSize: '1.25rem'}}>
                    {translate('or')}
                </Typography>}

                {!email_disabled &&
                <form className={classes.container} action={login_url} method="post">
                    <input type="hidden" value={CSRF_TOKEN} name="authenticity_token"/>
                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                variant="standard"
                                name="account[email]"
                                label={translate('email')}
                                className={classes.textField}
                                defaultValue={this.state.email}
                                onChange={this.handleChange('email')}
                                margin="normal"
                                fullWidth
                                type={'email'}
                                autoComplete={'username'}
                                required={true} />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField
                                variant="standard"
                                name={"account[password]"}
                                label={translate('password')}
                                className={classes.textField}
                                onChange={this.handleChange('password')}
                                margin="normal"
                                fullWidth
                                type={'password'}
                                autoComplete={'current-password'}
                                required={true} />
                        </Grid>
                        {Erp.rememberMe() && <Grid item xs={11} style={{marginTop: 24}}>
                            <div className={classes.rowTerms}>
                                <Checkbox
                                    name={'account[remember_me]'}
                                    checked={this.state.remember_me}
                                    value={"1"}
                                    classes={{
                                        root: classes.checkbox,
                                        checked: classes.checked,
                                    }}
                                />
                                <Typography variant="body1">{translate('remember_me')}</Typography>
                            </div>
                        </Grid>}
                        <Grid container justifyContent={'flex-end'}>
                            <Button variant="contained" size="medium" color="primary"
                                    className={classes.button} type={'submit'} name={'commit'}>
                                {translate('connect')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>}

                {!email_disabled &&
                <Grid container>
                    <Button size="small" color="primary" onClick={() => window.location = reset_url}>
                        {translate('forgot_pwd')}
                    </Button>
                </Grid>}
            </Fragment>
        );
    }
}
